import { generateAssistanceExcel } from "../../../utils/createAssistanceExcel"
import { useState, useEffect } from "react"
import { Button, FormControl, InputLabel, Select, MenuItem, TextField } from "@mui/material";
import {
    DataGrid,
    GridColDef,
} from "@mui/x-data-grid";
import { getProjectMarks } from "../../../services/assistMarkService";
import { getProjects } from "../../../services/proyectService";
import { fetchEmployees } from "../../../services/employeeService";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ProjectAssistanceModal from "./ProjectAssistanceModal";



export const AssitanceView = () => {
    const [ projects, setProjects ] = useState<Project[]>([]);
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ workers, setWorkers ] = useState<Employee[]>([]);
    const [ loadingExcel, setLoadingExcel ] = useState<boolean>(false);
    const [ startDate, setStartDate ] = useState<Date | null>(null);
    const [ endDate, setEndDate ] = useState<Date | null>(null);
    const [ projectType, setProjectType ] = useState<string>("Ambos");
    const [ projectNameFilter, setProjectNameFilter ] = useState<string>("");


    const [openModal, setOpenModal] = useState(false);
    const [selectedProject, setSelectedProject] = useState<Project | null>(null);
    const handleRowDoubleClick = (params: any) => {
        setSelectedProject(params.row);

        setOpenModal(true);
    };
    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedProject(null);
    };


    // Cargar proyectos del tipo "construction"
    const loadProjects = async () => {
        setLoading(true);
        const data = await getProjects("construction", "Adjudicado/Terminado", true);
        setProjects(data);

        const employees = await fetchEmployees();
        setWorkers(employees.filter((employee) => employee.roles.includes("Obrero")));
        setLoading(false);
    };

    // Cargar lista de proyectos filtrados por tipo
    const loadFilteredProjects = async (event:any) => {
        setLoading(true);
        setProjectType(event.target.value)

        let data = []
        if (event.target.value === "Ambos") {
            data = await getProjects("construction", "Adjudicado/Terminado", true);
        } else if (event.target.value === "Si Adjudicados") {
            data = await getProjects("construction", "Si Adjudicado");
        } else {
            data = await getProjects("construction", "Terminado");
        }
        
        setProjects(data);
        setLoading(false);
    };

    useEffect(() => {
        loadProjects();
    }, []);

    const handleGenerateAssistance = async (project: Project) => {
        try {
            setLoadingExcel(true)
            const assistance: AssistMark[] = await getProjectMarks(project.projectNumber)
            await generateAssistanceExcel(project, assistance, workers, startDate, endDate)
            setLoadingExcel(false)
        } catch (error) {
            setLoadingExcel(false)
        }
    }


    // Columnas de la tabla
    const columns: GridColDef[] = [
        { field: "projectName", headerName: "Nombre del Proyecto", flex: 1 },
        { field: "location", headerName: "Dirección", flex: 1 },
        {
            field: "actions",
            headerName: "Acciones",
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    size="small"
                    disabled={loadingExcel || !startDate || !endDate || endDate < startDate}
                    onClick={() => {handleGenerateAssistance(params.row)}}
                >
                    Descargar Asistencia
                </Button>
            ),
        },
    ];


    return (
        <div className="p-4">
            <h1>Proyectos con Asistencia</h1>

            <div className="mb-5 flex gap-4">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        format='dd/MM/yyyy'
                        label="Fecha de inicio"
                        value={startDate}
                        onChange={(newValue) => {
                            // set hours and minutes to 0 to avoid problems with the time
                            if (newValue) {
                                newValue.setHours(0);
                                newValue.setMinutes(0);
                            }
                            setStartDate(newValue)
                        }}
                    />
                    <DatePicker
                        format='dd/MM/yyyy'
                        label="Fecha de fin"
                        value={endDate}
                        onChange={(newValue) => {
                            // set hours and minutes to 23:59 to avoid problems with the time
                            if (newValue) {
                                newValue.setHours(23);
                                newValue.setMinutes(59);
                            }
                            setEndDate(newValue)
                        }}
                    />
                </LocalizationProvider>

                <FormControl className="w-1/5">
                    <InputLabel id="project-type-label">Project Type</InputLabel>
                    <Select
                        labelId="project-type-label"
                        id="project-type"
                        value={projectType}
                        onChange={loadFilteredProjects}
                    >
                        <MenuItem value="Si Adjudicados">Si Adjudicados</MenuItem>
                        <MenuItem value="Terminados">Terminados</MenuItem>
                        <MenuItem value="Ambos">Ambos</MenuItem>
                    </Select>
                </FormControl>

                <FormControl className="w-1/5">
                    <InputLabel shrink htmlFor="project-name">
                        Nombre Proyecto
                    </InputLabel>
                    <TextField
                        id="project-name"
                        variant="outlined"
                        value={projectNameFilter}
                        onChange={(e) => setProjectNameFilter(e.target.value)}
                    />
                </FormControl>
            </div>
            
            <div style={{ height: 600, width: "100%" }}>
                <DataGrid
                    rows={projects.filter((project) => project.projectName.includes(projectNameFilter))}
                    columns={columns}
                    pageSize={10}
                    getRowId={(row) => row._id}
                    loading={loading}
                    onRowDoubleClick={handleRowDoubleClick}
                />
            </div>


            <ProjectAssistanceModal
                open={openModal}
                onClose={handleCloseModal}
                selectedProject={selectedProject}
                workers={workers}
                startDate={startDate}
                endDate={endDate}
            />
        </div>
    )
}
