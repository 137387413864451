import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Routes } from '../../../config/Routes';
import { useNavigate } from 'react-router-dom';
import { getProjects } from '../../../services/proyectService';
import ProjectAccordion from './ProjectAccordion';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import handleGenerateExcel from '../../../utils/createExcel';
import ColorModal from './ColorLegendsModal';
interface ProjectsTabViewProps {
    type: string;
}

const ProjectsTabView: React.FC<ProjectsTabViewProps> = ({ type }) => {
    const navigate = useNavigate();
    const getFormattedType = (type: string) => {
        if (type === 'project') {
            return 'Proyecto';
        }
        if (type === 'construction') {
            return 'Obra';
        }
        return 'Todo'
    }
    const formattedType = getFormattedType(type);
    const [projects, setProjects] = useState<Project[]>([]);
    const [filteredProjects, setFilteredProjects] = useState<Project[]>([]);

    // Estados para los filtros
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [currency, setCurrency] = useState<string>('');
    const [userName, setUserName] = useState<string>('');
    const [projectName, setProjectName] = useState<string>('');
    const [rut, setRut] = useState<string>('');
    const [projectNumber, setProjectNumber] = useState<number>(0);
    const [legalName, setLegalName] = useState<string>('');
    const [approvedStatus, setApprovedStatus] = useState<string>('');
    const [location, setLocation] = useState<string>('');
    const [commune, setCommune] = useState<string>('');
    const [region, setRegion] = useState<string>('');

    const [ openColorModal, setOpenColorModal ] = useState(false);

    const filter = {
        startDate,
        endDate,
        currency,
        userName,
        projectName,
        rut,
        projectNumber,
        legalName,
        approvedStatus,
        location,
        commune,
        region,
    }

    const handleCreateClick = () => {
        navigate(Routes.NewProject, { state: { type } });
    };


    const handleUpdateProject = (project: Project) => {
        const newProjects = projects.map((p) => (p._id === project._id ? project : p));
        setProjects(newProjects);
        applyFilters();
    };

    const openColorsModal = () => {
        setOpenColorModal(true);
    };

    useEffect(() => {
        setStartDate(null);
        setEndDate(null);
        setCurrency('');
        setUserName('');
        setProjectName('');
        setRut('');
        setLegalName('');
        setProjectNumber(0);
        setApprovedStatus('');
        setLocation('');
        setCommune('');
        setRegion('');

        const filtered = localStorage.getItem('projectsFilter');
        getProjects(type).then((data) => {
            setProjects(data);
            setFilteredProjects(data); // Inicialmente, no se aplica ningún filtro
        });
    }, [type]);

    useEffect(() => {
        if (startDate !== null || endDate !== null || currency !== '' || userName !== '' || projectName !== '' || rut !== '' || legalName !== '' || projectNumber !== 0 || approvedStatus !== '' || location !== '' || commune !== '' || region !== '') {
            localStorage.setItem('projectsFilter', JSON.stringify(filter));
        }
        applyFilters();
    }, [startDate, endDate, currency, userName, projectName, projects, rut, legalName, projectNumber, approvedStatus, location, commune, region]);

    useEffect(() => {
        const filter = localStorage.getItem('projectsFilter');
        if (filter) {
            const parsedFilter = JSON.parse(filter);
            setStartDate(parsedFilter.startDate);
            setEndDate(parsedFilter.endDate);
            setCurrency(parsedFilter.currency);
            setUserName(parsedFilter.userName);
            setProjectName(parsedFilter.projectName);
            setRut(parsedFilter.rut);
            setLegalName(parsedFilter.legalName);
            setProjectNumber(parsedFilter.projectNumber);
            setApprovedStatus(parsedFilter.approvedStatus);
            setLocation(parsedFilter.location);
            setCommune(parsedFilter.commune);
            setRegion(parsedFilter.region);
        }
    }, []);

    const applyFilters = () => {
        let filtered = projects;

        if (startDate) {
            filtered = filtered.filter(project => new Date(project.date) >= startDate);
        }

        if (endDate) {
            filtered = filtered.filter(project => new Date(project.date) <= endDate);
        }

        if (currency) {
            filtered = filtered.filter(project => project.currency === currency);
        }

        if (userName) {
            filtered = filtered.filter(project => project.userName.toLowerCase().includes(userName.toLowerCase()));
        }

        if (projectName) {
            filtered = filtered.filter(project => project.projectName.toLowerCase().includes(projectName.toLowerCase()));
        }

        if (rut) {
            filtered = filtered.filter(project => project.rut.toLowerCase().includes(rut.toLowerCase()));
        }

        if (legalName) {
            filtered = filtered.filter(project => !!project.legalName && project.legalName.toLowerCase().includes(legalName.toLowerCase()));
        }

        if (projectNumber !== 0) {
            filtered = filtered.filter(project => project.projectNumber === projectNumber);
        }

        if (approvedStatus) {
            filtered = filtered.filter(project => project.approvedStatus === approvedStatus);
        }

        if (location) {
            filtered = filtered.filter(project => project.location.toLowerCase().includes(location.toLowerCase()));
        }

        if (commune) {
            filtered = filtered.filter(project => project.commune.toLowerCase().includes(commune.toLowerCase()));
        }

        if (region) {
            filtered = filtered.filter(project => project.region.toLowerCase().includes(region.toLowerCase()));
        }

        setFilteredProjects(filtered);
    };


    return (
        <div className="p-5 h-full flex flex-col overflow-auto">
            <h2 className="mb-5 text-xl">{`${formattedType}s`}</h2>
            <div className='flex justify-between pr-7 mb-2 gap-2'>

                <Button
                    variant='contained'
                    color='primary'
                    onClick={() => openColorsModal()}
                >
                    Guía de Colores 
                </Button>

                <div className='flex gap-2'>
                    <Button
                        variant='contained'
                        color='secondary'
                        onClick={() =>
                            handleGenerateExcel(
                                filteredProjects
                            )
                        }
                    >
                        Descargar Listado
                    </Button>
                    
                    
                    {formattedType !== "Todo" && (<Button variant='contained' onClick={handleCreateClick}>
                        Crear {formattedType}
                    </Button>)} 
                </div>
            </div>
            <div className="mb-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        format='dd/MM/yyyy'
                        label="Fecha de inicio"
                        value={startDate}
                        onChange={(newValue) => {
                            // set hours and minutes to 0 to avoid problems with the time
                            if (newValue) {
                                newValue.setHours(0);
                                newValue.setMinutes(0);
                            }
                            setStartDate(newValue)
                        }}
                    />
                    <DatePicker
                        format='dd/MM/yyyy'
                        label="Fecha de fin"
                        value={endDate}
                        onChange={(newValue) => {
                            // set hours and minutes to 23:59 to avoid problems with the time
                            if (newValue) {
                                newValue.setHours(23);
                                newValue.setMinutes(59);
                            }
                            setEndDate(newValue)
                        }}
                    />
                </LocalizationProvider>
                <FormControl fullWidth>
                    <InputLabel id="currency-label">Moneda</InputLabel>
                    <Select
                        labelId="currency-label"
                        id="currency"
                        value={currency}
                        label="Moneda"
                        onChange={(e) => setCurrency(e.target.value as string)}
                    >
                        <MenuItem value="">Todas</MenuItem>
                        <MenuItem value="Pesos">Pesos</MenuItem>
                        <MenuItem value="UF">UF</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    label="Nombre del Cliente"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    fullWidth
                />
                <TextField
                    label="Nombre del Proyecto"
                    value={projectName}
                    onChange={(e) => setProjectName(e.target.value)}
                    fullWidth
                />
                <TextField
                    label="RUT"
                    value={rut}
                    onChange={(e) => setRut(e.target.value)}
                    fullWidth
                />
                <TextField
                    label="Razón Social"
                    value={legalName}
                    onChange={(e) => setLegalName(e.target.value)}
                    fullWidth
                />
                <TextField
                    label="Número de Proyecto"
                    value={projectNumber}
                    onChange={(e) => setProjectNumber(Number(e.target.value))}
                    fullWidth
                />
                <TextField
                    label="Ubicación"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    fullWidth
                />
                <TextField
                    label="Comuna"
                    value={commune}
                    onChange={(e) => setCommune(e.target.value)}
                    fullWidth
                />
                <TextField
                    label="Región"
                    value={region}
                    onChange={(e) => setRegion(e.target.value)}
                    fullWidth
                />
                <FormControl fullWidth>
                    <InputLabel id="approved-label">Adjudicado</InputLabel>
                    <Select
                        labelId="approved-label"
                        id="approvedStatus"
                        value={approvedStatus}
                        label="Adjudicado"
                        onChange={(e) => setApprovedStatus(e.target.value as string)}
                    >
                        <MenuItem value="">Todos</MenuItem>
                        <MenuItem value="Si Adjudicado">Si Adjudicado</MenuItem>
                        <MenuItem value="No Adjudicado">No Adjudicado</MenuItem>
                        <MenuItem value="Sin Respuesta">Sin Respuesta</MenuItem>
                        <MenuItem value="Terminado">Terminado</MenuItem>
                    </Select>
                </FormControl>
            </div>

            <ColorModal
                open={openColorModal}
                onClose={() => setOpenColorModal(false)}
            />

            {filteredProjects.map((project) => (
                <ProjectAccordion key={project._id} project={project} handleProjectChange={handleUpdateProject} />
            ))}
        </div>
    );
}

export default ProjectsTabView;
