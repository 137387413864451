import React, { useState, useEffect } from "react";
import {
    DataGrid,
    GridColDef,
} from "@mui/x-data-grid";
import { Button, FormControl, InputLabel, TextField } from "@mui/material";
import { getProjects, updateProject } from "../../../services/proyectService";
import EditProjectModal from "./EditProjectModal";


interface Project {
    _id: string;
    location: string;
    latitude?: number;
    longitude?: number;
    takesAssistance?: boolean;
    projectName?: string;
    projectNumber?: number;
}

const ConstructionProjectsView: React.FC = () => {
    const [projects, setProjects] = useState<Project[]>([]);
    const [selectedProject, setSelectedProject] = useState<Project | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const [ projectNameFilter, setProjectNameFilter ] = useState<string>("");
    const [ projectNumberFilter, setProjectNumberFilter ] = useState<string>("");


    // Cargar proyectos del tipo "construction"
    const loadProjects = async () => {
        setLoading(true);
        const data = await getProjects("construction", "Si Adjudicado");
        setProjects(data);
        setLoading(false);
    };

    useEffect(() => {
        loadProjects();
    }, []);

    // Manejar actualización del proyecto
    const handleUpdateProject = async (updatedProject: Partial<Project>) => {
        if (!selectedProject) return;
        const success = await updateProject(selectedProject._id, updatedProject);
        if (success) {
            setIsModalOpen(false);
            setSelectedProject(null);
            loadProjects(); // Recargar proyectos tras la actualización exitosa
        } else {
            alert("Error al actualizar el proyecto.");
        }
    };

    // Columnas de la tabla
    const columns: GridColDef[] = [
        { field: "projectNumber", headerName: "Número de proyecto", flex: 1 },
        { field: "projectName", headerName: "Nombre del proyecto", flex: 1 },
        { field: "location", headerName: "Dirección", flex: 1 },
        {
            field: "takesAssistance",
            headerName: "Asistencia",
            flex: 1,
            valueFormatter: (params) => (params.value ? "Sí" : "No"),
        },
        {
            field: "actions",
            headerName: "Acciones",
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                        setSelectedProject(params.row);
                        setIsModalOpen(true);
                    }}
                >
                    Editar
                </Button>
            ),
        },
    ];

    return (
        <div className="p-4">
            <h1>Proyectos de Construcción</h1>

            <div className="flex gap-3 my-3">
                <FormControl className="w-1/5">
                    <InputLabel shrink htmlFor="project-number">
                        Numero Proyecto
                    </InputLabel>
                    <TextField
                        id="project-number"
                        variant="outlined"
                        value={projectNumberFilter}
                        onChange={(e) => setProjectNumberFilter(e.target.value)}
                    />
                </FormControl>

                <FormControl className="w-1/5">
                    <InputLabel shrink htmlFor="project-name">
                        Nombre Proyecto
                    </InputLabel>
                    <TextField
                        id="project-name"
                        variant="outlined"
                        value={projectNameFilter}
                        onChange={(e) => setProjectNameFilter(e.target.value)}
                    />
                </FormControl>
            </div>

            <div style={{ height: 600, width: "100%" }}>
                <DataGrid
                    rows={projects.filter((project: Project) =>
                        project.projectName?.toLocaleLowerCase().includes(projectNameFilter.toLocaleLowerCase()) &&
                        project.projectNumber?.toString().includes(projectNumberFilter)
                    )}
                    columns={columns}
                    pageSize={10}
                    getRowId={(row) => row._id}
                    loading={loading}
                />
            </div>

            {/* Modal para editar proyecto */}
            <EditProjectModal
                open={isModalOpen}
                onClose={() => {
                    setIsModalOpen(false);
                    setSelectedProject(null);
                }}
                project={selectedProject}
                onSubmit={handleUpdateProject}
            />
        </div>
    );
};

export default ConstructionProjectsView;
