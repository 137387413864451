import { useState } from "react";
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';

const colors = [
  { color: '#ffaaaa', label: "No Adjudicado" },
  { color: '#ffdee3', label: "Sin Respuesta" },
  { color: '#84c484', label: "Obra Adjudicada" },
  { color: '#c8e6c9', label: "Proyecto Adjudicado" },
  { color: '#b3e5fc', label: "Completado" },
];


interface EditPasswordModalProps {
  open: boolean;
  onClose: () => void;
}


const ColorLegendModal: React.FC<EditPasswordModalProps>  = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Simbología colores</DialogTitle>

      <DialogContent>

        <div className="space-y-2">
          {colors.map(({ color, label }) => (
            <div key={label} className="flex items-center space-x-2">
              <div className={`w-6 h-6 rounded`} style={{ background: `${color}` }} />
              <span>{label}</span>
            </div>
          ))}
        </div>

        <Button onClick={onClose}>Cerrar</Button>
      </DialogContent>
    </Dialog>
  );
};

export default ColorLegendModal;
