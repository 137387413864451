import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { convertToStringDate } from './dateConverter';


export const generateAssistanceExcel = async (project, AssistanceList, employees, initDate, endDate) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(`Listado de asistencia ${project.projectName}`);
    
    const columns = [{ header: 'Empleado', key: 'employee', width: 20 }];
    endDate.setDate(endDate.getDate() + 1);
    const days = Math.floor((endDate.getTime() - initDate.getTime()) / (1000 * 60 * 60 * 24));
    const maxEntriesPerDay = {};
    
    for (let i = 0; i < days; i++) {
        const actualDate = new Date(initDate);
        actualDate.setDate(initDate.getDate() + i);
        actualDate.setHours(4, 0, 0, 0);
        const formattedDate = actualDate.toLocaleDateString('es-ES');
        
        const dayEntries = employees.map(employee => {
            return AssistanceList.filter(assistance => 
                assistance.employeeUsername === employee.username &&
                new Date(assistance.date).toDateString() === actualDate.toDateString()
            );
        }).flat();

        const maxEntries = Math.max(dayEntries.filter(a => a.type === "entrada").length, 1);
        const maxExits = Math.max(dayEntries.filter(a => a.type === "salida").length, 1);

        maxEntriesPerDay[i] = { maxEntries, maxExits };
        
        for (let j = 0; j < maxEntries; j++) {
            columns.push({ header: `Entrada ${formattedDate} #${j + 1}`, key: `inAssistance${i}_${j}`, width: 20 });
        }
        for (let j = 0; j < maxExits; j++) {
            columns.push({ header: `Salida ${formattedDate} #${j + 1}`, key: `outAssistance${i}_${j}`, width: 20 });
        }
    }
    worksheet.columns = columns;

    
    employees.forEach(employee => {
        const myAssistance = AssistanceList.filter(a => a.employeeUsername === employee.username);
        const rowData = { employee: employee.username };
        
        for (let i = 0; i < days; i++) {
            const actualDate = new Date(initDate);
            actualDate.setDate(initDate.getDate() + i);
            actualDate.setHours(4, 0, 0, 0);
            const finishDate = new Date(actualDate);
            finishDate.setDate(finishDate.getDate() + 1);
            
            const inMarks = myAssistance.filter(a => 
                new Date(a.date) >= actualDate && 
                new Date(a.date) < finishDate && 
                a.type === "entrada"
            ).map(a => convertToStringDate(a.date).split(" ")[1].slice(0, 8)).sort();
            
            const outMarks = myAssistance.filter(a => 
                new Date(a.date) >= actualDate && 
                new Date(a.date) < finishDate && 
                a.type === "salida"
            ).map(a => convertToStringDate(a.date).split(" ")[1].slice(0, 8)).sort();
            
            for (let j = 0; j < maxEntriesPerDay[i].maxEntries; j++) {
                rowData[`inAssistance${i}_${j}`] = inMarks[j] || "Sin marca";
            }
            for (let j = 0; j < maxEntriesPerDay[i].maxExits; j++) {
                rowData[`outAssistance${i}_${j}`] = outMarks[j] || "Sin marca";
            }
        }
        const row = worksheet.addRow(rowData);


        
        Object.keys(rowData).forEach((key, index) => {
            if (key.startsWith("inAssistance") || key.startsWith("outAssistance")) {
                const cell = row.getCell(index + 1);
                cell.style.fill = {
                    type: 'pattern',
                    pattern: "solid",
                    fgColor: { argb: rowData[key] === 'Sin marca' ? 'FFDDDD' : 'CCFFCC' }
                };
            }
        });
    });
    
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, `Asistencia-${project.projectName}.xlsx`);
};


export const generateEmployeeAssistanceExcel = async (projects, AssistanceList, employee, initDate, endDate) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(`Listado de asistencia ${employee.username}`);
    
    const columns = [{ header: 'Proyecto', key: 'project', width: 20 }];
    endDate.setDate(endDate.getDate() + 1);
    const days = Math.floor((endDate.getTime() - initDate.getTime()) / (1000 * 60 * 60 * 24));
    const maxEntriesPerDay = {};
    
    for (let i = 0; i < days; i++) {
        const actualDate = new Date(initDate);
        actualDate.setDate(initDate.getDate() + i);
        actualDate.setHours(4, 0, 0, 0);
        const formattedDate = actualDate.toLocaleDateString('es-ES');
        
        const dayEntries = projects.map(project => {
            return AssistanceList.filter(assistance => 
                assistance.projectNumber === project.projectNumber &&
                assistance.employeeUsername === employee.username &&
                new Date(assistance.date).toDateString() === actualDate.toDateString()
            );
        }).flat();

        const maxEntries = Math.max(dayEntries.filter(a => a.type === "entrada").length, 1);
        const maxExits = Math.max(dayEntries.filter(a => a.type === "salida").length, 1);

        maxEntriesPerDay[i] = { maxEntries, maxExits };
        
        for (let j = 0; j < maxEntries; j++) {
            columns.push({ header: `Entrada ${formattedDate} #${j + 1}`, key: `inAssistance${i}_${j}`, width: 20 });
        }
        for (let j = 0; j < maxExits; j++) {
            columns.push({ header: `Salida ${formattedDate} #${j + 1}`, key: `outAssistance${i}_${j}`, width: 20 });
        }
    }
    worksheet.columns = columns;

    projects.forEach(project => {
        const projectAssistance = AssistanceList.filter(a => a.projectNumber === project.projectNumber && a.employeeUsername === employee.username);
        const rowData = { project: project.projectName };
        
        for (let i = 0; i < days; i++) {
            const actualDate = new Date(initDate);
            actualDate.setDate(initDate.getDate() + i);
            actualDate.setHours(4, 0, 0, 0);
            const finishDate = new Date(actualDate);
            finishDate.setDate(finishDate.getDate() + 1);
            
            const inMarks = projectAssistance.filter(a => 
                new Date(a.date) >= actualDate && 
                new Date(a.date) < finishDate && 
                a.type === "entrada"
            ).map(a => convertToStringDate(a.date).split(" ")[1].slice(0, 8)).sort();
            
            const outMarks = projectAssistance.filter(a => 
                new Date(a.date) >= actualDate && 
                new Date(a.date) < finishDate && 
                a.type === "salida"
            ).map(a => convertToStringDate(a.date).split(" ")[1].slice(0, 8)).sort();
            
            for (let j = 0; j < maxEntriesPerDay[i].maxEntries; j++) {
                rowData[`inAssistance${i}_${j}`] = inMarks[j] || "Sin marca";
            }
            for (let j = 0; j < maxEntriesPerDay[i].maxExits; j++) {
                rowData[`outAssistance${i}_${j}`] = outMarks[j] || "Sin marca";
            }
        }
        const row = worksheet.addRow(rowData);
        
        Object.keys(rowData).forEach((key, index) => {
            if (key.startsWith("inAssistance") || key.startsWith("outAssistance")) {
                const cell = row.getCell(index + 1);
                cell.style.fill = {
                    type: 'pattern',
                    pattern: "solid",
                    fgColor: { argb: rowData[key] === 'Sin marca' ? 'FFDDDD' : 'CCFFCC' }
                };
            }
        });
    });
    
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, `Asistencia-${employee.username}.xlsx`);
};
