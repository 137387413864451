import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
} from "@mui/material";
import {
    DataGrid,
    GridColDef,
} from "@mui/x-data-grid";
import { convertToStringDate } from '../../../utils/dateConverter';
import { getProjectMarks } from "../../../services/assistMarkService";


interface AssistanceModalProps {
    open: boolean;
    onClose: () => void;
    selectedProject: Project | null;
    workers: Partial<Employee>[] | null;
    startDate: Date | null;
    endDate: Date | null;
}



const ProjectAssistanceModal: React.FC<AssistanceModalProps> = ({
    open,
    onClose,
    selectedProject,
    workers,
    startDate,
    endDate,
}) => {

    const [columns, setColumns] = useState<GridColDef[]>([]);
    const [rows, setRows] = useState<any[]>([]);

    useEffect(() => {
        if (!selectedProject || !workers?.length || !startDate || !endDate) return;

        // Ajustar el rango de fechas
        const adjustedEndDate = new Date(endDate);
        adjustedEndDate.setDate(adjustedEndDate.getDate() + 1);

        const days = Math.floor((adjustedEndDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));

        // Crear las columnas dinámicamente
        const dynamicColumns: GridColDef[] = [
            { field: "worker", headerName: "Colaborador", resizable: true },
        ];

        for (let i = 0; i < days; i++) {
            const actualDate = new Date(startDate);
            actualDate.setDate(actualDate.getDate() + i);
            const formattedDate = actualDate.toLocaleDateString("es-ES");

            dynamicColumns.push(
                { field: `inAssistance${i}`, headerName: `Entrada ${formattedDate}`, width: 150, resizable: true },
                { field: `outAssistance${i}`, headerName: `Salida ${formattedDate}`, width: 150, resizable: true }
            );
        }

        setColumns(dynamicColumns);

        // Obtener las marcas de asistencia del empleado
        const fetchAssistance = async () => {
            const assistance: AssistMark[] = await getProjectMarks(selectedProject.projectNumber);

            const formattedRows = workers.map((worker) => {
                const workerAssistance = assistance.filter((a) => a.employeeUsername === worker.username);

                const rowData: any = { id: worker._id, worker: worker.name };

                for (let i = 0; i < days; i++) {
                    const actualDate = new Date(startDate);
                    actualDate.setDate(actualDate.getDate() + i);
                    actualDate.setHours(4, 0, 0, 0);

                    const nextDate = new Date(actualDate);
                    nextDate.setDate(nextDate.getDate() + 1);

                    const inMark = workerAssistance.find((a) =>
                        new Date(a.date) > actualDate && new Date(a.date) < nextDate && a.type === "entrada"
                    );
                    const outMark = workerAssistance.find((a) =>
                        new Date(a.date) > actualDate && new Date(a.date) < nextDate && a.type === "salida"
                    );

                    rowData[`inAssistance${i}`] = inMark ? convertToStringDate(inMark.date).split(" ")[1].slice(0, 8) : "Sin marca";
                    rowData[`outAssistance${i}`] = outMark ? convertToStringDate(outMark.date).split(" ")[1].slice(0, 8) : "Sin marca";
                };

                return rowData;
            });

            setRows(formattedRows);
        };

        fetchAssistance();
    }, [selectedProject, workers, startDate, endDate]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
            <DialogTitle>Asistencia Proyecto: {selectedProject?.projectName}</DialogTitle>
            <DialogContent>

                { (startDate && endDate) ?
                    (<div style={{ height: 500, width: "100%" }}>
                        <DataGrid rows={rows} columns={columns} pageSize={10} />
                    </div>) : 
                    (<div>
                        Por favor, selecciona fechas para la previsualización
                    </div>)
                }
                <Button onClick={onClose} sx={{ mt: 2 }} variant="contained" color="primary">
                    Cerrar
                </Button>
            </DialogContent>
        </Dialog>
    );
};

export default ProjectAssistanceModal;
